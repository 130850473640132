import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppInfo, StatusModel } from '../models/models';
import { fetchAppInfo } from './actions';

const initialState: AppInfo = {};

const initialStatus: StatusModel = {
  status: 'idle',
  error: null
};

export const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState: initialState,
  // Sync actions
  reducers: {
    setAppInfo: (state, action: PayloadAction<Partial<AppInfo>>) => {
      return { ...state, ...action.payload };
    }
  },
  // Async actions
  extraReducers(builder) {
    builder.addCase(
      fetchAppInfo.fulfilled,
      (state, action: PayloadAction<Partial<AppInfo>>) => {
        return { ...state, ...action.payload };
      }
    );
  }
});

export const statusSlice = createSlice({
  name: 'status',
  initialState: initialStatus,
  // Sync actions
  reducers: {},
  // Async actions
  extraReducers(builder) {
    builder
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(fetchAppInfo.pending, (state, action) => {
        state.status = 'loading';
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(fetchAppInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(fetchAppInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

const { actions } = appInfoSlice;
export const { setAppInfo } = actions;
