import React from 'react';
import { LabeledLink } from '../models/models';
import { FieldArray } from 'formik';
import Adder from './Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

interface DynListProps {
  sectionTitle: string;
  name: string;
  emptyMessage: string;
  links?: LabeledLink[];
}

const DynList = ({ sectionTitle, name, emptyMessage, links }: DynListProps) => {
  return (
    <>
      <label className="form-label">{sectionTitle}</label>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div className="list-group">
            <Adder arrayHelpers={arrayHelpers} name={name} />
            {links && links.length > 0 ? (
              links.map((item, index) => (
                <div
                  key={index}
                  className="list-group-item list-group-item-action"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{item.label}</h5>
                    <small
                      role="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <FontAwesomeIcon icon={faRemove} />
                    </small>
                  </div>
                  <p>{item.url}</p>
                </div>
              ))
            ) : (
              <div className="list-group-item list-group-item-action">
                {emptyMessage}
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

export default DynList;
