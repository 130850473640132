import React, { useEffect } from 'react';
import { Jumbotron } from './Jumbotron';
import { useAppSelector, useAppDispatch } from '../hooks/app-hooks';
import { RootState } from '../store/store';
import { fetchAppInfo } from '../store/actions';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonLinks } from './ButtonLinks';
import { VideoCards } from './VideoCards';

export const Main = () => {
  const dispatch = useAppDispatch();
  const configData = useAppSelector((state: RootState) => state.appInfo);
  const { status } = useAppSelector((state: RootState) => state.status);

  useEffect(() => {
    // https://www.techiediaries.com/react-18-useeffect/
    // useEffect runs twice in StrictMode in React 18
    dispatch(fetchAppInfo());
    console.log('fetch app info called');

    return () => console.log('Cleanup..');
  }, []);

  return status === 'loading' ? (
    <div className="text-center py-5">
      <Spinner animation="border" />
    </div>
  ) : (
    <main role="main">
      <Jumbotron
        appName={configData.appName}
        description={configData.description}
        overviewVideo={configData.appVideo}
      />
      <div className="album py-5 bg-light">
        <div className="container">
          <div>
            <h2>Installation Instructions</h2>
            <p>
              Install the following packages in order. Ensure to select Install
              for Admins Only while installing all packages:
            </p>
            <ButtonLinks list={configData.packageLinks} />
            <p>
              The video below is a quick guide showing you how to install and
              run the Language I/O Chat.
            </p>
          </div>
          <div>
            <h2>How to demo</h2>
            <VideoCards list={configData.demoGuides} />
          </div>
          <div>
            <h2>Additional Resources</h2>
            <p>
              If you want to promote the application to your customers, here are
              some useful resources to share, and a link to our AppExchange
              listing.
            </p>
            <ButtonLinks list={configData.setupInstructions} />
          </div>
          <div>
            <h2>Where to Get Help</h2>
            <p>
              For demo or installation questions or if you need help or support,
              you can contact us directly:
            </p>
            <a href={`mailto:${configData.contactEmail}`}>
              <i className="icon icon-envelope"></i>
              <span>{configData.contactEmail}</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
