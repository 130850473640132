import React from 'react';
export const Footer: React.FC = () => {
  return (
    <footer className="text-muted py-5">
      <div className="container">
        <p className="float-right">
          <a href="#">Back to top</a>
        </p>
        <p className="text-center">
          &copy; 2022 Language I/O, Inc. All Rights Reserved
        </p>
      </div>
    </footer>
  );
};
