import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppInfo } from '../models/models';
import Api from '../services/service';

export const fetchAppInfo = createAsyncThunk<Partial<AppInfo>>(
  'demokit/init',
  async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkApi
  ) => {
    const response = await Api.fetchAppInfo('chat');
    return response;
  }
);

export const updateAppInfo = createAsyncThunk<{ sucess: string }, AppInfo>(
  'demokit/update',
  async (
    configData: AppInfo,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkApi
  ) => {
    const response = await Api.updateAppInfo(configData);
    return response;
  }
);
