import React from 'react';

interface ILink {
  label?: string;
  url?: string;
}

export type LinkList = ILink[];

interface LinksProp {
  list?: LinkList;
}

export const Links: React.FC<LinksProp> = ({ list }): JSX.Element => {
  return (
    <ul className="list-group">
      {list &&
        list.map((object, index) => (
          <li key={index}>
            <a target="_blank" href={object.url} rel="noreferrer">
              {object.label}
            </a>
          </li>
        ))}
    </ul>
  );
};
