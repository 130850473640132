import React from 'react';

interface ILink {
  label?: string;
  url?: string;
}

export type LinkList = ILink[];

interface LinksProp {
  list?: LinkList;
}

export const VideoCards: React.FC<LinksProp> = ({ list }): JSX.Element => {
  return (
    <>
      {list?.map((item: ILink) => {
        return (
          <>
            <p>{item.label}</p>
            <div className="card p-2 border-0">
              <div className="card-body">
                <div className="ratio ratio-16x9">
                  <iframe src={item.url} allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
