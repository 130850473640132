import React, { useState, useEffect } from 'react';

interface ThumbProps {
  file: File;
}

const Thumb = ({ file }: ThumbProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thumb, setThumb] = useState<string>();

  const handleChange = React.useCallback(
    (blob: File) => {
      setIsLoading(true);
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        setIsLoading(false);

        if (
          fileReader.result instanceof ArrayBuffer ||
          fileReader.result === null
        ) {
          setThumb(undefined);
          return;
        }

        setThumb(fileReader.result);
      };

      if (!blob) {
        setIsLoading(false);
        return;
      }

      fileReader.readAsDataURL(blob);
    },
    [setIsLoading, setThumb]
  );

  useEffect(() => {
    if (file) {
      handleChange(file);
    } else {
      setThumb(undefined);
    }
  }, [file]);

  if (!file) {
    return null;
  }

  if (isLoading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={thumb}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200}
    />
  );
};

export default Thumb;
