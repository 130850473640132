import { AppInfo } from '../models/models';
import Api from './api';

export default {
  fetchAppInfo: async (app?: string) => {
    const response = await Api().get(`/object/${app}`);
    return response.data;
  },
  updateAppInfo: async (data: AppInfo) => {
    const response = await Api().put(``, data);
    return response.data;
  }
};
