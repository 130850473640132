import React from 'react';

interface ILink {
  label?: string;
  url?: string;
}

export type LinkList = ILink[];

interface LinksProp {
  list?: LinkList;
}

export const ButtonLinks: React.FC<LinksProp> = ({ list }): JSX.Element => {
  return (
    <>
      {list?.map((item: ILink) => {
        return (
          <p key={item.label}>
            <a href={item.url} className="btn btn-primary m-2">
              {item.label}
            </a>
          </p>
        );
      })}
    </>
  );
};
