import React from 'react';
import { Field, FieldArrayRenderProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

interface AdderProps {
  name: string;
  arrayHelpers: FieldArrayRenderProps;
}

const Adder = ({ name, arrayHelpers }: AdderProps) => {
  return (
    <div className="list-group-item list-group-item-action">
      <div className="d-flex w-100 justify-content-between">
        <div className="form-floating mb-1">
          <Field
            type="text"
            className="form-control"
            name={`${name}Label`}
            placeholder="Label"
          />
          <label htmlFor={`${name}Label`}>Label</label>
        </div>
        <small
          role="button"
          onClick={() => {
            arrayHelpers.push({
              url: arrayHelpers.form.values[`${name}Link`],
              label: arrayHelpers.form.values[`${name}Label`]
            });
          }}
        >
          <FontAwesomeIcon icon={faAdd} color="blue" />
        </small>
      </div>
      <div className="form-floating">
        <Field
          type="url"
          name={`${name}Link`}
          className="form-control"
          placeholder="name@example.com"
        />
        <label htmlFor={`${name}Link`}>Link</label>
      </div>
    </div>
  );
};

export default Adder;
