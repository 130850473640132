import React from 'react';
import pic from '../chat-translation-salesforce.jpg';

interface JumbotronProps {
  appName?: string;
  description?: string;
  overviewVideo?: string;
}

export const Jumbotron = ({
  appName,
  description,
  overviewVideo
}: JumbotronProps) => {
  return (
    <section className="jumbotron my-1 bg-white">
      <div className="container">
        <h1 className="jumbotron-heading">{appName}</h1>
        <p className="lead text-muted">{description}</p>
        {overviewVideo && (
          <p className="text-center p-2">
            <div className="card border-0">
              <div className="card-body">
                <div className="ratio ratio-16x9">
                  <iframe src={overviewVideo} allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </p>
        )}
      </div>
    </section>
  );
};
