import React, { useEffect } from 'react';
import { useAppDispatch } from '../hooks/app-hooks';
import { fetchAppInfo, updateAppInfo } from '../store/actions';
import Card from 'react-bootstrap/Card';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AppInfo } from '../models/models';
import DynList from './DynList';
import Thumb from './Thumb';

const ConfigPage = () => {
  const dispatch = useAppDispatch();
  const appInfo = {
    appName: '',
    appLogo: null,
    contactEmail: '',
    packageLinks: [],
    appVideo: '',
    briefDescription: '',
    description: '',
    setupInstructions: [],
    packageLinksLabel: '',
    packageLinksLink: '',
    setupInstructionsLabel: '',
    setupInstructionsLink: '',
    demoGuides: [],
    demoGuidesLabel: '',
    demoGuidesLink: ''
  };

  function onSubmit(fields: AppInfo) {
    console.log(fields);
    dispatch(updateAppInfo(fields));
  }

  const validationSchema = Yup.object().shape({
    appName: Yup.string().required('This field is required!')
  });

  return (
    <div className="container">
      <Formik
        initialValues={appInfo}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setValues, setFieldValue, values }) => {
          useEffect(() => {
            // https://www.techiediaries.com/react-18-useeffect/
            // useEffect runs twice in StrictMode in React 18
            dispatch(fetchAppInfo())
              .unwrap()
              .then((app) => {
                console.log(app);
                setValues(app, false);
                setFieldValue('packageLinksLabel', '', false);
                setFieldValue('packageLinksLink', '', false);
                setFieldValue('setupInstructionsLabel', '', false);
                setFieldValue('setupInstructionsLink', '', false);
                setFieldValue('demoGuidesLabel', '', false);
                setFieldValue('demoGuidesLink', '', false);
              });

            return () => console.log('Cleanup..');
          }, []);

          return (
            <Card>
              <Card.Body>
                <Form className="row g-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="appName"
                      >
                        App Name
                      </label>
                      <div className="col-sm-10">
                        <Field
                          name="appName"
                          type="text"
                          placeholder="Application Name"
                          className={
                            'form-control' +
                            (errors.appName && touched.appName
                              ? ' is-invalid'
                              : '')
                          }
                          value={values.appName}
                        />
                        <ErrorMessage
                          name="appName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="contactEmail"
                      >
                        Contact Email
                      </label>
                      <div className="col-sm-10">
                        <Field
                          name="contactEmail"
                          type="email"
                          className="form-control"
                          value={values.contactEmail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="appLogo"
                      >
                        App Logo
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          className="form-control"
                          accept="image/*"
                          name="appLogo"
                          placeholder="App Logo"
                          onChange={(event) => {
                            event.currentTarget.files &&
                              event.currentTarget.files.length > 0 &&
                              setFieldValue(
                                'appLogo',
                                event.currentTarget.files[0]
                              );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {values.appLogo && values.appLogo.name && (
                      <Thumb file={values.appLogo} />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="appVideo"
                      >
                        Overview Video
                      </label>
                      <div className="col-sm-10">
                        <Field
                          name="appVideo"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="briefDescription">
                      Brief Description
                    </label>
                    <Field
                      name="briefDescription"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="description">
                      Description
                    </label>
                    <Field
                      name="description"
                      as="textarea"
                      rows="2"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <DynList
                      sectionTitle="Package Links"
                      name="packageLinks"
                      links={values.packageLinks}
                      emptyMessage="no package added"
                    />
                  </div>
                  <div className="col-md-6">
                    <DynList
                      sectionTitle="Setup Instructions"
                      name="setupInstructions"
                      links={values.setupInstructions}
                      emptyMessage="no instruction added"
                    />
                  </div>
                  <div className="col-md-6">
                    <DynList
                      sectionTitle="Demo Guides"
                      name="demoGuides"
                      links={values.demoGuides}
                      emptyMessage="no guide added"
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
};

export default ConfigPage;
