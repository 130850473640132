import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import logo from '../logo.svg';
import { LinkList, Links } from './Links';

interface IKit {
  appLogo?: string;
  appName?: string;
  briefDescription?: string;
  description?: string;
  contactEmail?: string;
  chatterURL?: string;
  packageLinks?: LinkList;
  demoGuides?: LinkList;
  setupInstructions?: LinkList;
}

const Kit = () => {
  const [kitData] = useState<IKit>({
    appName: 'Language I/O Chat',
    briefDescription: 'Translation live chat conversation',
    description: `Salesforce is an advanced CRM system, hosted in the cloud and extremely easy to use.  
    It follows the software as a service model and is perfect for all types of businesses!`,
    contactEmail: 'support@languageio.com',
    chatterURL: 'chatter@salesforce.com',
    packageLinks: [
      { label: 'Chat', url: 'https://test.com' },
      { label: 'Response', url: 'https://response.com' }
    ],
    demoGuides: [
      { label: 'Chat Demo', url: 'https://test.com' },
      { label: 'Response Demo', url: 'https://response.com' }
    ],
    setupInstructions: [
      { label: 'Installation guide', url: 'https://zendesk.com' }
    ]
  });
  return (
    <div className="mainArea">
      <div className="container">
        <div className="row">
          <Card>
            <div className="row no-gutters">
              <div className="col-md-4">
                <Card.Img src={logo} />
              </div>
              <div className="col-md-8">
                <Card.Body>
                  <Card.Title>{kitData.appName}</Card.Title>
                  <Card.Text>{kitData.briefDescription}</Card.Text>
                </Card.Body>
              </div>
            </div>
          </Card>
        </div>
        <div className="row">
          <hr className="featurette-divider" />
        </div>
        <div className="row">
          <h4>{kitData.description}</h4>
        </div>
        <div className="row">
          <hr className="featurette-divider" />
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-4">
            <h2>
              <small>Package Links:</small>
            </h2>
            <Links list={kitData.packageLinks} />
          </div>
          <div className="col-xs-6 col-sm-4">
            <h2>
              <small>Demo Guides:</small>
            </h2>
            <Links list={kitData.demoGuides} />
          </div>
          <div className="col-xs-6 col-sm-4">
            <h2>
              <small>Setup Instructions:</small>
            </h2>
            <Links list={kitData.setupInstructions} />
          </div>
        </div>
        <div className="row">
          <hr className="featurette-divider" />
        </div>
        <div className="row contactArr">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="pull-left">
              <h4>Contact Info</h4>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <a href={`mailto:{kitData.contactEmail}`}>{kitData.contactEmail}</a>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <a href={kitData.chatterURL} target="_blank" rel="noreferrer">
              {kitData.chatterURL}
            </a>
          </div>
        </div>
        <div className="row">
          <hr className="featurette-divider" />
        </div>
      </div>
      <div id="push" />
    </div>
  );
};

export default Kit;
